<template>
  <div class="mt-5">
    <!--EDITAR-->
    <ed-modal v-model="bind.boolExibirCadastro" width="70%">
      <div slot="title">
        {{ formData.strNome }}
        {{
          formData.intId
            ? ":: Serviço #" + formData.intId
            : "Inserir Novo Serviço"
        }}
      </div>

      <div class="" slot="content">
        <v-tabs v-model="strTab" centered icons-and-text>
          <v-tab href="#tab-geral">
            Cadastro Geral
            <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
          </v-tab>
          <v-tab href="#tab-fiscal">
            Informações Fiscais
            <i :class="$utilidade.getIcone('cadastroFiscal') + ' fa-2x'"></i>
          </v-tab>
        </v-tabs>

        <ed-form
          :formData="formData"
          :handlerSave="salvar"
        >
          <servico-geral
            :formData="formData"
            v-show="strTab == 'tab-geral'"
            :key="'geral-' + $root.$session.versao"
          />

          <servico-fiscal
            :formData="formData"
            v-show="strTab == 'tab-fiscal'"
            :key="'fiscal-' + $root.$session.versao"
          />
        </ed-form>
      </div>
    </ed-modal>

    <!--LISTAR-->
    <ed-table
    :key="$root.$session.versao"
      v-if="!boolOcultarLista"
      strModel="Common/Parametro"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :loading="loading"
      :filter="formFiltros"
      @register="onRegister"
      @edit="onEdit"
      @search="getServico"
    />
  </div>
</template>

<script>
import {
  EdTable,
  EdModal,
  EdForm,
} from "@/components/common/form";

import ServicoGeral from "./servico.geral.vue";
import ServicoFiscal from "./servico.fiscal.vue";

export default {
  name: "pageCadastroFuncao",
  props: {
    boolOcultarLista: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  components: {
    EdTable,
    EdModal,
    EdForm,
    ServicoGeral,
    ServicoFiscal
  },
  inject: [],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      strTab: "tab-geral",
      arrayHeaders: [
        {
          text: "Código",
          sortable: true,
          value: "strCodigo",
        },
        {
          text: "Descrição",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Ativo",
          sortable: true,
          value: "boolAtivo",
        },
      ],
      arrayRows: [],
      objPagination: null,
      bind: {
        boolExibirCadastro: false,
        objParametroPai:null
      },
      formFiltros: {
        boolBuscarParaLista: 1,
        strChave: "financeiroServico",
      },
      formData: {
        strNome: null,
        strDescricao:null,
        intParametroId: null,
        strValor: {},
        boolAtivo: 1,
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);
      this.$root.$request
        .get("Common/Parametro", { strChave: this.formFiltros.strChave })
        .then((objResult) => {
          this.loading = false;

          if (objResult.status == 200 && objResult.result) {
            this.bind.objParametroPai = objResult.result;
          }
          this.getServico();
        });
    },
    onRegister() {
      this.formData = {
        intId: 0,
        intParametroId: this.bind.objParametroPai.intId,
        strNome: null,
        strDescricao: null,
        strCodigo:this.arrayRows.length+1,
        strValor: {},
        boolAtivo: 1,
        boolPermitirEdicao:1,
        boolPermitirExclusao:1
      };
      this.bind.boolExibirCadastro = true;
    },

    onEdit(data) {
      this.bind.boolExibirCadastro = true;

      if(!data.strValor){
        data.strValor = {}
      }
      this.formData = Object.assign(data);
    },

    getServico() {
      if (this.boolOcultarLista) return;

      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Common/Parametro", this.formFiltros)
        .then((objResult) => {
          this.loading = false;
          this.$root.$dialog.loading(false);

          if (objResult.status == 200) {
            this.objPagination = objResult.result;

            let arrayRows = [];

            objResult.result.data.forEach((objModel) => {
              let item = {
                intId: objModel.intId,
                strNome: objModel.strNome,
                strCodigo: objModel.strCodigo,
                boolAtivo: this.$utilidade.parseBoolean(objModel.boolAtivo),
                _item: objModel,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .put("Common/Parametro", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.getServico();
            this.bind.boolExibirCadastro = !this.bind.boolExibirCadastro
          }
        });
    },
  },
};
</script>
