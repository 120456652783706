<template>
  <div class="row ma-0 pa-0">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 text-left">
      <span class="form-title">Atividades</span>

      <div class="row mb-2">
        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.strValor.strCnae"
          label="Ramo de Atividade"
          name="strCnae"
          route="Common/Cnae"
          itemValue="strCodigo"
          rules="required"
          initializeItems
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.strValor.strAtividade"
          label="Atividade"
          name="strCnae"
          route="Common/Atividade"
          itemValue="strCodigo"
          rules="required"
          initializeItems
        />

        <ed-input-editor
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strValor.strDescricao"
          label="Descrição"
          name="strDescricao"
        />
      </div>

      <span class="form-title">Dados Fiscais</span>
      <div class="row">
        <ed-input-money
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formData.strValor.floatAliquota"
          label="Alíquota (%)"
          name="floatAliquota"
          rules="required"
        />

        <ed-input-money
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formData.strValor.floatPis"
          label="PIS (%)"
          name="floatPis"
          rules="required"
          info="Calculado sobre o faturamento total"
        />

        <ed-input-money
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formData.strValor.floatConfins"
          label="COFINS (%)"
          name="floatConfins"
          rules="required"
          info="Calculado sobre o faturamento total"
        />

        <ed-input-money
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formData.strValor.floatCsll"
          label="CSLL (%)"
          name="floatCsll"
          rules="required"
          info="Calculado sobre o faturamento total"
        />

        <ed-input-money
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formData.strValor.flaotIr"
          label="IRRF (%)"
          name="flaotIr"
          rules="required"
          info="Calculado sobre o faturamento líquido"
        />

        <ed-input-money
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formData.strValor.flaotInss"
          label="INSS (%)"
          name="flaotInss"
          rules="required"
          info="Calculado sobre o faturamento total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputMoney,
  EdInputSelect,
  EdInputEditor,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intFuncaoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputMoney,
    EdInputSelect,
    EdInputEditor,
    EdInputAutoComplete,
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>
