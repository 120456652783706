<template>
  <div v-if="bind.boolCarregado">
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-servicos">
        Produtos e Serviços
        <i :class="$utilidade.getIcone('servico') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-forma-pagamento">
        Forma de Pagamento
        <i :class="$utilidade.getIcone('gerenciar') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-status">
        Status
        <i :class="$utilidade.getIcone('gerenciar') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-classificacao">
        Classificação
        <i :class="$utilidade.getIcone('gerenciar') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-categoria-receita">
        Categoria Receitas
        <i :class="$utilidade.getIcone('receita') + ' fa-2x'"></i>
      </v-tab>

      <v-tab href="#tab-categoria-despesa">
        Categoria Despesa
        <i :class="$utilidade.getIcone('despesa') + ' fa-2x'"></i>
      </v-tab>

      <v-tab href="#tab-cobranca-recorrente">
        Cobrança Recorrente
        <i :class="$utilidade.getIcone('recorrent') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <servico
      v-if="strTab == 'tab-servicos'"
      :key="'tipo-servicos-' + $root.$session.versao"
    />

    <parametro
      v-if="strTab == 'tab-forma-pagamento'"
      strChave="financeiroFormaPagamento"
      :key="'tab-forma-pagamento-' + $root.$session.versao "
    />

    <parametro
      v-if="strTab == 'tab-categoria-receita'"
      strChave="financeiroCategoriaReceita"
      :key="'tab-categoria-receita-' + $root.$session.versao "
    />

    <parametro
      v-if="strTab == 'tab-categoria-despesa'"
      strChave="financeiroCategoriaDespesa"
      :key="'tab-categoria-receita-' + $root.$session.versao "
    />

    <parametro
      v-if="strTab == 'tab-status'"
      strChave="financeiroStatus"
      :key="'tab-categoria-receita-' + $root.$session.versao "
    />

    <parametro
      v-if="strTab == 'tab-classificacao'"
      strChave="financeiroClassificacao"
      :key="'tab-categoria-classificacao-' + $root.$session.versao "
    />


    
    <cobranca-recorrente
      v-if="strTab == 'tab-cobranca-recorrente'"
      :key="'tab-cobranca-recorrente-' + $root.$session.versao "
    />

  </div>
</template>

<script>
import Servico from "./partials/servico.vue";
import CobrancaRecorrente from "./partials/cobrancaRecorrente.vue";
import Parametro from "@/components/common/parametro/editar";
export default {
  name: "pageCadastroFuncao",
  props: {},
  components: {
    Servico,
    CobrancaRecorrente,
    Parametro,
  },
  provide() {
    return {
      bind: this.bind,
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        boolCarregado: false,
      },
      strTab: "tab-servicos",
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.bind.boolCarregado = true;
    },
  },
};
</script>
