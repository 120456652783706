<template>
  <div class="mt-5">
    <!--EDITAR-->
    <ed-modal
      v-model="bind.boolExibirModalCadastro"
      width="70%"
      :scrollable="false"
    >
      <div slot="title">Adicionar Nova Cobrança Recorrente</div>

      <div class="" slot="content" v-if="formDataConteudo">
        <ed-form
          :formData="formData"
          :handlerSave="editar"
          v-if="!this.$root.$session.loadingContent"
        >
          <div class="row">
            <div
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left"
            >
              <span class="form-title">Informações do lançamento</span>

              <div class="row">
                <ed-input-select
                  v-if="$root.$session.filiais.length > 1"
                  class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                  v-model="formDataConteudo.intFilialId"
                  label="Filial"
                  name="filiais"
                  rules="required"
                  :items="$root.$session.filiais"
                  @selected="(x) =>{setObjeto('filial',x)}"
                />

                <ed-input-select
                  class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                  v-model="formDataConteudo.strDestinoType"
                  label="Filtrar por"
                  name="strDestinoType"
                  rules="required"
                  :items="bind.arrayTipoDestino"
                />

                <ed-input-auto-complete
                  class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0"
                  v-model="formDataConteudo.intDestinoId"
                  label="Cliente/Fornecedor"
                  name="intDestinoId"
                  :route="
                    formDataConteudo.strDestinoType ==
                    'App\\Models\\Tenant\\CadastroGeral'
                      ? 'Cadastro/CadastroGeral'
                      : 'Cadastro/Profissional'
                  "
                  rules="required"
                  :initializeItems="true"
                  @selected="(x) =>{setObjeto('destino',x)}"
                />

                <ed-input-tipo
                  class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0"
                  v-model="formDataConteudo.intTipoMovimentoId"
                  name="intTipoMovimentoId"
                  label="Tipo"
                  tipo="1442"
                  rules="required"
                  @selected="(x) =>{setObjeto('tipo_movimento',x)}"
                />

                <ed-input-select
                  class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0"
                  v-model="formDataConteudo.intParametroCategoriaId"
                  :disabled="!formDataConteudo.intTipoMovimentoId  ? true : false"
                  label="Categoria"
                  name="intParametroCategoriaId"
                  rules="required"
                  :items="
                    formDataConteudo.intTipoMovimentoId == 1443
                      ? bind.arrayCategoriaReceita
                      : bind.arrayCategoriaDespesa
                  "
                  @selected="(x) =>{setObjeto('categoria',x)}"
                />
<!-- 
                <ed-input-select
                  class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pt-0"
                  v-model="formDataConteudo.intParametroStatusId"
                  :disabled="!formDataConteudo.intTipoMovimentoId  ? true : false"
                  label="Status"
                  name="intParametroStatusId"
                  :items="bind.arrayStatus"
                /> -->

                <ed-input-text
                  class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0"
                  v-model="formDataConteudo.strDescricao"
                  :disabled="!formDataConteudo.intTipoMovimentoId  ? true : false"
                  name="strDescricao"
                  label="Descrição"
                  rules="required"
                />

                <ed-input-select
                  class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pt-0"
                  v-model="formDataConteudo.intDiaLancamento"
                  :disabled="!formDataConteudo.intTipoMovimentoId  ? true : false"
                  label="Dia do Lançamento"
                  name="intDiaLancamento"
                  :items="bind.arrayDiaLancamento"
                  rules="required"
                  @selected="(x) =>{setObjeto('dia_lancamento',x)}"
                />

                <ed-input-tipo
                  class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pt-0"
                  v-model="formDataConteudo.intTipoCompetenciaId"
                  :disabled="!formDataConteudo.intTipoMovimentoId  ? true : false"
                  name="intTipoCompetenciaId"
                  label="Competência"
                  info="É a Data da Competência em que o lançamento será registrado"
                  tipo="1445"
                  rules="required"
                  @selected="(x) =>{setObjeto('tipo_compentencia',x)}"
                />

                <ed-input-select
                  class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pt-0"
                  v-model="formDataConteudo.intParametroFormaPagamentoId"
                  :disabled="!formDataConteudo.intTipoMovimentoId  ? true : false"
                  label="Forma de Pagamento"
                  name="intParametroFormaPagamentoId"
                  :items="bind.arrayFormaPagamento"
                  rules="required"
                  @selected="(x) =>{setObjeto('forma_pagamento',x)}"
                />

                <ed-input-auto-complete
                  class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pt-0"
                  v-model="formDataConteudo.intContaBancariaId"
                  :disabled="!formDataConteudo.intTipoMovimentoId  ? true : false"
                  label="Conta Bancária"
                  name="intContaBancariaId"
                  route="Configuracoes/ContaBancaria"
                  rules="required"
                  :initializeItems="true"
                  @selected="(x) =>{setObjeto('conta_bancaria',x)}"
                />
              </div>

              <span class="form-title">Serviços</span>

              <div class="row">
                <div
                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0"
                >
                  <ed-simple-table
                    :config="{
                      registerName: 'Inserir novo Serviço',
                    }"
                    @register="inserirServico"
                    disabledSearchRows
                  >
                    <thead>
                      <tr>
                        <th class="text-left">Serviço</th>
                        <th class="text-left" width="250">Valor</th>
                        <th class="text-left" width="100"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(servico, index) in formDataConteudo.servicos"
                        :key="index"
                      >
                        <td>
                          <ed-input-select
                            class="mt-3"
                            v-model="servico.intParametroServivoId"
                            name="intParametroServivoId"
                            :items="bind.arrayServico"
                            rules="required"
                            hideDetails
                            @selected="(x) =>{setServico(servico,x)}"
                          />
                        </td>
                        <td>
                          <ed-input-money
                            class="mt-3"
                            v-model="servico.floatValor"
                            hideDetails
                          />
                        </td>
                        <td>
                          <ed-button
                            @click="removerServico(index)"
                            :iconLeft="$utilidade.getIcone('deletar')"
                            icon
                            onlyIcon
                            title="Remover serviço"
                            color="error"
                          />
                        </td>
                      </tr>
                      <template v-if="formDataConteudo.servicos.length">
                        <tr
                          :style="
                            'color:' +
                            (formDataConteudo.intTipoMovimentoId == 1443 ? 'green' : 'red')
                          "
                        >
                          <th>
                            TOTAL
                          </th>
                          <th>
                            R$
                            {{$utilidade.floatToMoney(getTotalMovimento(this.formDataConteudo.servicos))}}
                          </th>
                          <th></th>
                        </tr>
                      </template>
                    </tbody>
                  </ed-simple-table>
                </div>
              </div>
            </div>
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-simple-table
      :config="{ registerName: 'Inserir nova Cobrança Recorrente' }"
      @register="onRegister"
      disabledSearchRows
    >
      <thead>
        <tr>
          <th class="text-left">Tipo</th>
          <th class="text-left">Filial</th>
          <th class="text-left">Cliente/Fornecedor</th>
          <th class="text-left">Categoria</th>
          <th class="text-left">Dia do Lançamento</th>
          <th class="text-left">Competência</th>
          <!-- <th class="text-left">Status</th> -->
          <th class="text-left">Valor</th>
          <th class="text-left">Descrição</th>
          <th class="text-left"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,index) in formData.strValor" :key="index">
          <td>
            {{ item.tipo_movimento.strNome }}
          </td>
          <td>
            {{ item.filial.strNome }}
          </td>
          <td>
            {{ item.destino.strNome }}
          </td>
          <td>
            {{ item.categoria.strNome }}
          </td>
          <td>
            {{ item.dia_lancamento.strNome }}
          </td>
          <td>
            {{ item.tipo_compentencia.strNome }}
          </td>
          <td>
            R$ {{ $utilidade.floatToMoney(getTotalMovimento(item.servicos))}}
          </td>
          <td>
            {{ item.strDescricao}}
          </td>
          <td>
            <ed-button
              @click="editarServico(item,index)"
              :iconLeft="$utilidade.getIcone('editar')"
              icon
              onlyIcon
              title="Editar serviço"
              color="success"
            />

            <ed-button
              @click="removerServicoSalvo(index)"
              :iconLeft="$utilidade.getIcone('deletar')"
              icon
              onlyIcon
              title="Remover serviço"
              color="error"
            />
          </td>
        </tr>

      </tbody>
    </ed-simple-table>
  </div>
</template>

<script>
import {
  EdSimpleTable,
  EdModal,
  EdButton,
  EdForm,
  EdInputDate,
  EdInputTipo,
  EdInputSelect,
  EdInputText,
  EdInputMoney,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    boolOcultarLista: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  components: {
    EdSimpleTable,
    EdModal,
    EdButton,
    EdForm,
    EdInputDate,
    EdInputTipo,
    EdInputSelect,
    EdInputText,
    EdInputMoney,
    EdInputAutoComplete,
  },
  inject: [],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        boolExibirModalCadastro: false,
        arrayCategoriaDespesa: [],
        arrayCategoriaReceita: [],
        arrayFormaPagamento: [],
        arrayDiaLancamento: [],
        arrayServico: [],
        arrayStatus: [],
        arrayTipoDestino: [
          {
            intId: "App\\Models\\Tenant\\CadastroGeral",
            strNome: "Empresas e Parceiros",
          },
          {
            intId: "App\\Models\\Tenant\\Profissional",
            strNome: "Profissionais",
          },
        ],
      },
      formDataConteudo: null,
      formData: {
        intId: 0,
        strNome: "Financeiro Cobrança Padrão",
        strChave: "financeiroCobrancaPadrao",
        intParametroId:null,
        boolAtivo: 1,
        boolPadrao: 0,
        boolPermitirEdicao: 1,
        boolPermitirExclusao: 1,
        strValor: [],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {

    initialize() {
      this.getParametro();
    },

    setObjeto(strNome,strValor){
      console.log('setObjeto => '+strNome,strValor)

      if(strValor){
        this.formDataConteudo[strNome] = strValor
      }
    },

    setServico(item,valor){
      if(valor){
        item.servico = valor
      }
    },

    inserirServico() {
      this.formDataConteudo.servicos.push({
        servico: {},
        floatValor: null,
      });
    },

    removerServico(index) {
      this.formDataConteudo.servicos.splice(index, 1);
    },

    removerServicoSalvo(index){
      this.formData.strValor.splice(index, 1);
      this.salvar()
    },

    editarServico(servico,index){
      servico.intId = index+1;

      this.formDataConteudo = Object.assign(servico,{});
      this.bind.boolExibirModalCadastro = !this.bind.boolExibirModalCadastro;
    },

    onRegister() {
      this.formDataConteudo = Object.assign(
        {
          intId:0,
          intFilialId: this.$root.$session.filial
            ? this.$root.$session.filial.intId
            : null,
          filial:this.$root.$session.filial,
          dia_lancamento:{
            strNome:'DIA 1 DE CADA MÊS'
          },
          intTipoMovimentoId: null,
          intParametroCategoriaId: null,
          intParametroStatusId: null,
          intParametroFormaPagamentoId: null,
          intContaBancariaId: null,
          intParcelamento: 1,
          intDiaLancamento: 1,
          intTipoCompetenciaId:1447,
          tipo_compentencia:{
            strNome:'MÊS ANTERIOR AO DIA DO LANÇAMENTO'
          },
          intDestinoId: null,
          strDestinoType: "App\\Models\\Tenant\\CadastroGeral",
          strDescricao: null,
          strObservacao: "Cobrança recorrente registrado automaticamente",
          servicos: [],
        },
        {}
      );
      this.bind.boolExibirModalCadastro = !this.bind.boolExibirModalCadastro;
    },

    getTotalMovimento(servicos){
      let floatValorTotal = 0;

      servicos.forEach(element => {
        floatValorTotal += Number(this.$utilidade.moneyToFloat(element.floatValor))
      });
      
      return floatValorTotal;
    },

    getParametro() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Common/Parametro", {
          arrayParametro: [
            "financeiroCobrancaPadrao",
            "financeiroCategoriaReceita",
            "financeiroCategoriaDespesa",
            "financeiroStatus",
            "financeiroServico",
            "financeiroFormaPagamento",
            "financeiroServico",
          ],
        })
        .then((objResult) => {
          this.loading = false;
          this.$root.$dialog.loading(false);

          console.log("objResult", objResult);

          if (objResult.status == 200 && objResult.result) {
            if (objResult.result.financeiroCobrancaPadrao) {
              this.formData = Object.assign(
                objResult.result.financeiroCobrancaPadrao,
                {}
              );
            }

            if (objResult.result.financeiroStatus) {
              this.bind.arrayStatus =
                objResult.result.financeiroStatus.parametros;
            }

            if (objResult.result.financeiroCategoriaDespesa) {
              this.bind.arrayCategoriaDespesa =
                objResult.result.financeiroCategoriaDespesa.parametros;
            }

            if (objResult.result.financeiroCategoriaReceita) {
              this.bind.arrayCategoriaReceita =
                objResult.result.financeiroCategoriaReceita.parametros;
            }

            if (objResult.result.financeiroFormaPagamento) {
              this.bind.arrayFormaPagamento =
                objResult.result.financeiroFormaPagamento.parametros;
            }

            if (objResult.result.financeiroServico) {
              this.bind.arrayServico =
                objResult.result.financeiroServico.parametros;
            }

            for (let index = 1; index <= 25; index++) {
              const element = {
                intId: index,
                strNome: "DIA " + index + " DE CADA MÊS",
              };
              this.bind.arrayDiaLancamento.push(element);
            }
          }
        });
    },

    editar(){

      if(!this.getTotalMovimento(this.formDataConteudo.servicos)){
        this.$root.$dialog.warning("Informe pelo menos 1 serviço.");
        return;
      }

      if(!this.formData.strValor){
        this.formData.strValor = [];
      }
      
      if(this.formDataConteudo.intId){
        this.formData.strValor[this.formDataConteudo.intId-1] = Object.assign(this.formDataConteudo,{});
      }
      else{
        this.formData.strValor.push(this.formDataConteudo);
      }

      this.salvar();
    },

    salvar() {
      this.$root.$dialog.loading(true);
      this.$root.$request
        .put("Common/Parametro", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.getParametro();
            this.bind.boolExibirModalCadastro = false;
          }
        });
    },
  },
};
</script>
